:root {
  --base-a: #6e6e6e;
  --base-a-hover: #b7b7b7;
  --base-font-family: "Roboto", serif;

  /* template One */
  --temp-one-title-color: #221577;
  --temp-one-blur1: #c1f5ff;
  --temp-one-blur2: rgb(238 210 255);
  --temp-one-gradient-one: #34acd6;
  --temp-one-gradient-two: #98cb43;
  --temp-one-gradient-one-dark: #0f508f;
  --temp-one-gradient-two-dark: #287822;
  --temp-one-gradient-two-darks: #0b5f47;
  --temp-one-font-size-titr: 2.8rem;
  --temp-one-font-size-titr-w600: 2.2rem;
  --temp-one-light-bg: #f6f7fb;
  --temp-one-text-color: #867eb5;
  --temp-one-title-color: #221577;

  /* dashboard */
  --nav_back: #00779b;
  --white: #fff;
}
*,
::after,
::before {
  box-sizing: border-box;
  list-style-type: none;
  /*for ul li*/
  text-decoration: none;
  /*for a underline*/
  border: 0;
  margin: 0;
  padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

footer,
header,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  font-family: var(--base-font-family);
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  overflow-x: hidden;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

a {
  color: var(--base-a);
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: var(--base-a-hover);
  text-decoration: underline;
}

textarea:focus {
  /* color: red; */
  border: none !important;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}
